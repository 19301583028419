import React, { Component } from 'react';

export default class Buttons extends Component {
  constructor(props){
    super(props);
    this.state = {
      isLoad:null
    };
  }

  componentDidMount(){
    this.setState({isLoad:this.props.isLoad});
  }

  render(){
    return(
      <div style={{position: 'fixed', zIndex: 10, display:'flex', flexDirection: 'column', gap: '0.5rem', width: 'fit-content', height:'fit-content', padding: '2rem', right: '0', bottom: '0'}}>
        <a href='#header'><button className='btn btn-secondary'><svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6 15l6 -6l6 6" /></svg></button></a>
        <a href='#paginationWrapper'><button className='btn btn-secondary'><svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6 9l6 6l6 -6" /></svg></button></a>
    </div>
    );
  }
}