import React, { Component } from "react";

import Calculation from "./Calculation";
import Errorapi from "./Errorapi";
import HomeLevel from "./HomeLevel";
import HomeTotal from "./HomeTotal";
import Loader from "./Loader";
import axios from "axios";
import moment from 'moment';

var _ = require('lodash');

let pttl = [],
  isTruncate = [],
  pages = [],
  prevPage,
  nst = [];

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      apiUrl: "",
      token: "",
      isLoad: true,
      ckeys: [],
      ctitles: [],
      lkeys: [],
      ltitles: [],
      shwt: [],
      data: [],
      fullData: [],
      truncateTextView: "",
      itempp: "",
      decim: "",
      pagination: {},
      downloadHide: "",
      sortData: {},
      fbct: "",
      fbcl: "",
      msg: "",
      showCount: "",
      dat: "",
      format: ""
    };
  }

  setSortData = (sortColumn = "init", isAscending = "init") => {
    if (sortColumn === "init" && isAscending === "init") {
      this.setState({
        sortData: {
          sortColumn: localStorage.getItem("state.sortColumn")
            ? localStorage.getItem("state.sortColumn")
            : "confirmed",
          isAscending: localStorage.getItem("state.isAscending")
            ? localStorage.getItem("state.isAscending") === "true"
            : false,
        },
      });
    } else {
      this.setState({
        sortData: { sortColumn, isAscending },
      });
    }
  };

  async componentDidMount() {
    this.setSortData();
    const url = new URL(window.location.href);

    // setting state
    await this.setState({
      title: url.searchParams.get("report_title") || "",
      apiUrl: this.getapiurl(url.search),
      token: url.searchParams.get("token") || "",
      data_key: url.searchParams.get("data_key") || "response.data",
      hideExcelHeader: url.searchParams.get("hideExcelHeader") == '1' || false,
      truncateTextView: url.searchParams.get("truncate_text_view") || "",
      itempp: url.searchParams.get("items_per_page") || "",
      downloadHide: url.searchParams.get("downloadHide") || "",
      decim: url.searchParams.get("decimal_upto") || "",
      strc: url.searchParams.get("showTotalRowsCount") || "",
      fbct: url.searchParams.get("formula_based_column_title") || "",
      fbcl: url.searchParams.get("formula_based_column_logic") || "",
      tableExcelSearchBox: "",
      dat: url.searchParams.get("dateTimeColumnForSorting") || "",
      format: url.searchParams.get("dateTimeColumnFormat") || "",
      showCount: url.searchParams.get("showCount") || "",
      enableLocalSearch: url.searchParams.get("enableLocalSearch") != null ? url.searchParams.get("enableLocalSearch") : 'true',
      ckeys: url.searchParams.get("columns_keys")
        ? url.searchParams.get("columns_keys").split(",")
        : [],
      lkeys: url.searchParams.get("link_keys")
        ? url.searchParams.get("link_keys").split(",")
        : [],
      ltitles: url.searchParams.get("link_titles")
        ? url.searchParams.get("link_titles").split(",")
        : [],
      ctitles: url.searchParams.get("column_titles")
        ? url.searchParams.get("column_titles").split(",")
        : [],
      shwt: url.searchParams.get("showTotalFor")
        ? url.searchParams.get("showTotalFor").split(",")
        : [],
      showtimestamp: url.searchParams.get("showtimestamp") ? url.searchParams.get("showtimestamp") : 1,
      report_timezone: url.searchParams.get("report_timezone") ? url.searchParams.get("report_timezone") : 'Asia/Kolkata',
      send_token_in_header: url.searchParams.get("sendinheader") == "token",
    });

    document.title = this.state.title || "Simplifii - A versatile business apps platform";
    if (this.state.truncateTextView === "")
      this.setState({ truncateTextView: "25" });

    if (url.search.includes("getapiurlparam")) {
      let amp = url.search.split("&"),
        r;
      this.state.apiUrl += "?";
      for (r = 0; r < amp.length; ++r) {
        if (amp[r].includes("getapiurlparam")) {
          this.state.apiUrl += `${amp[r].split("___").reverse()[0]}&`;
        }
      }
    }

    if (this.state.fbct) {
      if (this.state.fbct.includes(",")) {
        if (this.state.fbct.split(",").length) {
          this.state.fbct.split(",").forEach((a) => {
            this.state.ckeys.push(a);
            this.state.ctitles.push(a);
          });
        }
      } else {
        this.state.ckeys.push(this.state.fbct);
        this.state.ctitles.push(this.state.fbct);
      }
    }

    // making api call    
    if (this.state.token !== "") {
      if (this.state.itempp !== "") {
        await axios({
          url: decodeURIComponent(`${this.state.apiUrl}` + (this.state.send_token_in_header ? '' : `&token=${this.state.token}`) + `&items_per_page=${this.state.itempp}`),
          headers: {
            Authorization: this.state.send_token_in_header ? this.state.token : undefined
          }
        })
          .then((res) =>
            this.setState({
              data: _.get(res.data, this.state.data_key),
              api_data_copy: _.get(res.data, this.state.data_key),
              pagination: res.data.response.pagination,
              isLoad: false,
            })
          )
          .catch((err) => this.setState({ msg: "Some Error occured" }));
      } else {
        await axios({
          url: decodeURIComponent(
            `${this.state.apiUrl}` + (this.state.send_token_in_header ? '' : `&token=${this.state.token}`)
          ),
          headers: this.state.send_token_in_header ? {
            Authorization: this.state.token
          } : undefined
        })
          .then((res) =>
            this.setState({ data: _.get(res.data, this.state.data_key), api_data_copy: _.get(res.data, this.state.data_key), isLoad: false })
          )
          .catch((err) => this.setState({ msg: "Some Error occured" }));
      }
    } else {
      const token = new URLSearchParams(decodeURIComponent(this.state.apiUrl)).get("token")
      if (this.state.itempp !== "") {
        await axios({
          url: decodeURIComponent(
            `${this.state.apiUrl}&items_per_page=${this.state.itempp}`,
          ),
          headers: {
            Authorization: (this.state.send_token_in_header && token) ? token : undefined
          }
        })
          .then((res) =>
            this.setState({
              data: _.get(res.data, this.state.data_key),
              api_data_copy: _.get(res.data, this.state.data_key),
              pagination: _.get(res.data, 'response.pagination') || false,
              isLoad: false,
            })
          )
          .catch((err) => this.setState({ msg: "Some Error occured" }));
      } else {
        await axios({
          url: decodeURIComponent(this.state.apiUrl),
          headers: {
            Authorization: (this.state.send_token_in_header && token) ? token : undefined
          }
        })
          .then((res) =>
            this.setState({ data: _.get(res.data, this.state.data_key), api_data_copy: _.get(res.data, this.state.data_key), isLoad: false })
          )
          .catch((err) => this.setState({ msg: "Some Error occured" }));
      }
    }

    if (this.state.showCount === "true") {
      this.state.ckeys.unshift("#");
      this.state.ctitles.unshift("#");
      this.showCount();
    }

    let i = 0;
    this.getShowTotal();

    this.setState({
      data: this.state.data.filter(
        (a) => a["specialhandingforwebapp_temp_skipthiscard"] !== "undefined"
      ),
    });
    nst = this.getnst();

    // setting truncate array with 0s
    isTruncate = new Array(
      this.state.data.length * this.state.ctitles.length
    ).fill(false);
    pages = new Array(this.state.pagination["last_page"]).fill(false);
    pages[0] = true;
    prevPage = 0;

    if (this.state.decim !== "") {
      let obj,
        k,
        samp = this.state.data;
      for (obj = {}, i = 0; i < samp.length; ++i) {
        obj = samp[i];
        for (k in obj) {
          if (typeof obj[k] === "number") {
            if (obj[k] % 1)
              samp[i][k] = obj[k].toFixed(parseInt(this.state.decim));
          }
        }
      }
      this.setState({ data: samp });
    }

    this.basicCalc();

    // force Updating
    this.forceUpdate();
  }

  isLink(value) {
    if (typeof value == "string") {
      if (value.startsWith('https://') || value.startsWith('http://')) {
        return true;
      }
    }
    return false;
  }

  isinLinkKeys(value) {
    return this.state.lkeys.includes(value);
  }

  returnIndexLTitle(value) {
    const index = this.state.lkeys.findIndex(item => item === value);
    return this.state.ltitles[index]
  }

  showCount = () => {
    if (Object.keys(this.state.pagination).length) {
      let arr = [],
        o = {},
        per,
        cp;
      this.state.data.forEach((a, i) => {
        o = a;
        cp = this.state.pagination.current_page;
        per = this.state.pagination.per_page;
        o["#"] = (cp - 1) * per + (i + 1);
        arr.push(o);
      });
      this.setState({ data: arr });
    } else {
      let arr = [],
        o = {};
      this.state.data.forEach((a, i) => {
        o = a;
        o["#"] = i + 1;
        arr.push(o);
      });
      this.setState({ data: arr });
    }
  };

  getShowTotal = () => {
    let i = 0,
      j,
      k,
      x;
    this.state.shwt.forEach((b, bi) => {
      const f = this.state.ckeys.indexOf(b);
      if (f !== -1) {
        pttl[f] = 0;
        this.state.data.forEach((a) => {
          if (b.includes(".")) {
            k = b.split(".");
            if (k && k[0] in a) {
              x = a[k[0]];
              for (j = 1; j < k.length; ++j) {
                if (x && (k[j] in x)) x = x[k[j]];
                else x = "";
              }
              pttl[f] += parseFloat(x) || 0;
            } else pttl[f] = "";
          } else {
            if (b in a) {
              if (typeof a[b] === "string")
                pttl[f] += parseFloat(a[b].replace(",", "")) || 0;
              else pttl[f] += parseFloat(a[b]) || 0;
            }
          }
        });
        pttl[f] = pttl[f].toFixed(2);
        if (pttl[f] - parseInt(pttl[f]) === 0) pttl[f] = parseInt(pttl[f]);
      } else {
        const f = this.state.ckeys.length + bi;
        pttl[f] = 0;
        this.state.data.forEach((a) => {
          if (b.includes(".")) {
            k = b.split(".");
            if (k && k[0] in a) {
              x = a[k[0]];
              for (j = 1; j < k.length; ++j) {
                if (x && (k[j] in x)) x = x[k[j]];
                else x = "";
              }
              pttl[f] += parseFloat(x) || 0;
            } else pttl[f] = "";
          } else {
            if (b in a) {
              if (typeof a[b] === "string")
                pttl[f] += parseFloat(a[b].replace(",", "")) || 0;
              else pttl[f] += parseFloat(a[b]) || 0;
            }
          }
        });
        pttl[f] = pttl[f].toFixed(2);
        if (pttl[f] - parseInt(pttl[f]) === 0) pttl[f] = parseInt(pttl[f]);
      }
    });
    // console.log(pttl);

    for (i = 0; i < pttl.length; ++i) {
      pttl[i] = pttl[i] || "";
    }
    for (; i < this.state.ctitles.length; ++i) {
      pttl[i] = "";
    }
    for (i = 0; i < pttl.length; ++i) {
      if (pttl[i] !== "") {
        if (pttl[i] < 0) {
          if (typeof pttl[i] === "string")
            pttl[i] = `-${Math.abs(pttl[i].replace(",", ""))}`;
          else pttl[i] = `-${Math.abs(pttl[i])}`;
        } else pttl[i] = pttl[i].toString();
      }
    }
  };

  getapiurl(u) {
    let arr, i, ch, n;
    if (u) arr = u.split("&");
    for (ch = "", n = arr.length, i = 0; i < n; ++i) {
      if (arr[i].includes("get_api_url")) {
        ch = arr[i].slice(12);
        break;
      }
    }
    for (++i; i < n; ch += "&" + arr[i], ++i);
    return ch;
  }

  onClick(u, i) {
    if (typeof u === "string") {
      if (u.length > this.state.truncateTextView) {
        let obj = isTruncate;
        obj[i] = !obj[i];

        this.setState({ isTruncate: obj });
      }
    }
  }

  getnst = () => {
    let j,
      x,
      k,
      nst = [];
    this.state.data.forEach((b) => {
      this.state.ckeys.forEach((a) => {
        if (a.includes(".")) {
          k = a.split(".");
          if (k && k[0] in b) {
            x = b[k[0]];
            for (j = 1; j < k.length; ++j) {
              if (x && (k[j] in x)) x = x[k[j]];
              else x = "";
            }
            nst.push(x);
          } else nst.push("");
        } else nst.push(b[a]);
      });
    });
    return nst;
  };

  async changePage(u) {
    if (u > -1 && u < pages.length) {
      if (this.state.token !== "") {
        await axios({
          url: decodeURIComponent(
            `${this.state.apiUrl}` + (this.state.send_token_in_header ? '' : `&token=${this.state.token}`) + `&items_per_page=${this.state.itempp
            }&page=${u + 1}`
          ),
          headers: {
            Authorization: this.state.send_token_in_header ? this.state.token : undefined
          }
        })
          .then((res) =>
            this.setState({
              data: _.get(res.data, this.state.data_key),
              tableExcelSearchBox: "",
              pagination: res.data.response.pagination,
            })
          )
          .catch((err) => this.setState({ msg: "Some Error occured" }));
      } else {
        const token = new URLSearchParams(decodeURIComponent(this.state.apiUrl)).get("token")
        await axios({
          url: decodeURIComponent(
            `${this.state.apiUrl}&items_per_page=${this.state.itempp}&page=${u + 1
            }`
          ),
          headers: {
            Authorization: (this.state.send_token_in_header && token) ? token : undefined
          }
        })
          .then((res) =>
            this.setState({
              data: _.get(res.data, this.state.data_key),
              tableExcelSearchBox: "",
              pagination: res.data.response.pagination,
            })
          )
          .catch((err) => this.setState({ msg: "Some Error occured" }));
      }
      if (this.state.showCount === "true") this.showCount();
      pages[prevPage] = false;
      pages[u] = true;
      prevPage = u;
      this.basicCalc();
      pttl = [];
      this.getShowTotal();
      nst = [];
      nst = this.getnst();
      this.forceUpdate();
      window.scrollTo(0, 0);
    }
  }

  basicCalc = () => {
    if (this.state.fbcl) {
      if (this.state.fbcl.includes(",")) {
        this.setState({
          data: Calculation(
            this.state.data,
            this.state.fbcl.split(","),
            this.state.fbct.split(",")
          ),
        });
      } else {
        this.setState({
          data: Calculation(
            this.state.data,
            [this.state.fbcl],
            [this.state.fbct]
          ),
        });
      }
    }
  };

  nstSort = (sortColumn) => {
    let j,
      a1 = [],
      a2 = [];
    j = 0;
    this.state.data.forEach((a) => {
      a2 = [];
      this.state.ckeys.forEach((b) => {
        a2.push(nst[j]);
        ++j;
      });
      a1.push(a2);
    });
    j = this.state.ckeys.indexOf(sortColumn);
    a1.sort((a, b) => {
      let value1 = a[j],
        value2 = b[j];
      value1 = value1 === null ? "" : value1;
      value2 = value2 === null ? "" : value2;
      if (typeof value1 === "string" && typeof value2 === "string") {
        let isnum1, isnum2;
        value1 = value1.toUpperCase();
        value2 = value2.toUpperCase();
        isnum1 = /^\d+$/.test(value1);
        isnum2 = /^\d+$/.test(value2);
        if (this.state.dat !== "" && this.state.dat.includes(sortColumn)) {
          let arr = this.checkDat(sortColumn, value1, value2);
          value1 = arr[0];
          value2 = arr[1];
          // console.log(value1);
        } else if (
          isnum1 ||
          isnum2 ||
          value1.includes("%") ||
          value2.includes("%")
        ) {
          if (value1 === "") value2 = parseFloat(value2);
          else if (value2 === "") value1 = parseFloat(value1);
          else {
            value1 = parseFloat(value1);
            value2 = parseFloat(value2);
          }
        }
      }
      if (
        typeof value1 === "string" ||
        typeof value2 === "string" ||
        typeof value1 === "object" ||
        typeof value2 === "object"
      ) {
        if (this.state.sortData.isAscending)
          return value1 > value2 ? 1 : value1 < value2 ? -1 : 0;
        else return value1 < value2 ? 1 : value1 > value2 ? -1 : 0;
      } else {
        if (this.state.sortData.isAscending) return value1 - value2;
        else return value2 - value1;
      }
    });
    j = 0;
    a1.forEach((a) => {
      a.forEach((b) => {
        nst[j] = b;
        ++j;
      });
    });
  };

  checkDat = (sortColumn, value1, value2) => {
    let v1, v2;
    if (this.state.dat.includes(",")) {
      if (this.state.dat.split(",").indexOf(sortColumn) !== -1) {
        // v1 = new Date(value1);
        // v2 = new Date(value2);
        let i = this.state.dat.split(',').indexOf(sortColumn);
        let format = this.state.format.split(',')[i];
        v1 = value1 ? moment(value1, format) : null;
        v2 = value2 ? moment(value2, format) : null;
      }
    } else if (this.state.dat === sortColumn) {
      // v1 = new Date(value1);
      // v2 = new Date(value2);
      let format = this.state.format;
      v1 = value1 ? moment(value1, format) : null;
      v2 = value2 ? moment(value2, format) : null;
    }
    return [v1, v2];
  };

  doSort = () => {
    if (this.state.sortData.sortColumn !== "confirmed") {
      let j, k, value1, value2;
      this.nstSort(this.state.sortData.sortColumn);
      this.state.data.sort((StateData1, StateData2) => {
        const sortColumn = this.state.sortData.sortColumn;
        if (sortColumn.includes(".")) {
          k = sortColumn.split(".");
          if (k[0] in StateData1 && k[0] in StateData2) {
            value1 = StateData1[k[0]];
            for (j = 1; j < k.length; value1 = value1[k[j]], ++j);
            value2 = StateData2[k[0]];
            for (j = 1; j < k.length; value2 = value2[k[j]], ++j);
          }
        } else {
          value1 = StateData1[sortColumn];
          value2 = StateData2[sortColumn];
        }
        value1 = value1 === null ? "" : value1;
        value2 = value2 === null ? "" : value2;
        if (typeof value1 === "string" && typeof value2 === "string") {
          let isnum1, isnum2;
          value1 = value1.toUpperCase();
          value2 = value2.toUpperCase();
          isnum1 = /^\d+$/.test(value1);
          isnum2 = /^\d+$/.test(value2);
          if (this.state.dat !== "" && this.state.dat.includes(sortColumn)) {
            let arr = this.checkDat(sortColumn, value1, value2);
            value1 = arr[0];
            value2 = arr[1];
            value1 = value1 === 'Invalid date' ? '' : value1;
            value2 = value2 === 'Invalid date' ? '' : value2;
          } else if (isnum1 || isnum2) {
            if (value1 === "") value2 = parseFloat(value2);
            else if (value2 === "") value1 = parseFloat(value1);
            else {
              value1 = String(value1).toLowerCase();
              value2 = String(value2).toLowerCase();
            }
          }
        }
        if (this.state.sortData.isAscending) {
          return value1 > value2 ? 1 : value1 < value2 ? -1 : 0; // Change equality logic as needed
        } else {
          return value1 < value2 ? 1 : value1 > value2 ? -1 : 0; // Change equality logic as needed
        }
             
      });
      // console.log(this.state.data);
    }
  };

  handleSort = (e) => {
    let currentsortColumn = e.currentTarget
      .querySelector("abbr")
      .getAttribute("title"),
      i = this.state.ctitles.indexOf(currentsortColumn);
    currentsortColumn = this.state.ckeys[i];
    if (this.state.sortData.isAscending == true && this.state.sortData.sortColumn === currentsortColumn) {
      this.setState({
        sortData: {
          sortColumn: "confirmed",
          isAscending: false,
        },
        data: this.updateDataWithSearchFlags([...this.state.api_data_copy])
      });

      localStorage.removeItem("state.sortColumn");
      localStorage.removeItem("state.isAscending");
      return
    }
    const isAscending =
      this.state.sortData.sortColumn === currentsortColumn
        ? !this.state.sortData.isAscending
        : this.state.sortData.sortColumn === currentsortColumn;
    this.setState({
      sortData: {
        sortColumn: currentsortColumn,
        isAscending: isAscending,
      },
    });
    localStorage.setItem("state.sortColumn", currentsortColumn);
    localStorage.setItem("state.isAscending", isAscending);
    // this.doSort();
  };

  updateDataWithSearchFlags = (data) => {
    const keys = this.state.ckeys;
    const searchKeyWord = this.state.tableExcelSearchBox ? (this.state.tableExcelSearchBox + "").toLowerCase() : "";
    const updatedData = [...data];
    updatedData.forEach((row, index) => {
      updatedData[index]['tableExcelSearchBoxshowFlag'] = false;
      try {
        for (let i = 0; i < keys.length; i++) {
          const key = keys[i];
          const cellValue = _.get(row, key);
          if (cellValue && (cellValue + "").toLowerCase().includes(searchKeyWord)) {
            updatedData[index]['tableExcelSearchBoxshowFlag'] = true;
            break;
          }
        }
      } catch (err) { }
    });

    return updatedData
  }

  searchFilter() {
    const updatedData = this.updateDataWithSearchFlags(this.state.data);
    this.setState({ data: updatedData });
  }

  render() {
    this.doSort();
    if (this.state.msg !== "") return <Errorapi msg={this.state.msg} />;
    else if (this.state.isLoad) return <Loader isLoad={this.state.isLoad} />;
    else
      return (
        <div
          className=""
          style={{ width: '98vw' }}
        >
          <div
            className=""
            style={{
              width: "100%",
              padding: '20px'
            }}
          >
            <HomeLevel
              title={this.state.title}
              apiUrl={this.state.apiUrl}
              ckeys={this.state.ckeys}
              data_key={this.state.data_key}
              send_token_in_header={this.state.send_token_in_header}
              ctitles={this.state.ctitles}
              shwt={this.state.shwt}
              downloadHide={this.state.downloadHide}
              token={this.state.token}
              decim={this.state.decim}
              fbcl={this.state.fbcl}
              fbct={this.state.fbct}
              showCount={this.state.showCount}
              hideExcelHeader={this.state.hideExcelHeader}
              report_timezone={this.state.report_timezone}
              showtimestamp={this.state.showtimestamp}
              data={this.state.data}
            />

            {this.state.shwt.length || this.state.strc ? (
              <HomeTotal
                ctitles={this.state.ctitles}
                pttl={pttl}
                strc={this.state.strc}
                pagination={this.state.pagination}
                data={this.state.data}
                shwt={this.state.shwt}
              />
            ) : (
              ""
            )}
            {this.state.enableLocalSearch == 'true' ?
              <div className="container mw-100 mx-0 px-0 mb-3 fadeInUp">
                <div className="row mx-0 px-0">
                  <div className="col-9 mx-0 pl-0 pr-2">
                    <input value={this.state.tableExcelSearchBox} onKeyDown={(value) => {
                      if (value.key === 'Enter') {
                        this.searchFilter()
                      }
                    }} onChange={(value) => {
                      this.setState({ tableExcelSearchBox: value.target.value })
                    }} placeholder={"Search (Type and press enter)"} style={{ width: '100%', border: 'none', background: '#f8f9fa', padding: '7px', borderRadius: '5px' }} />
                    {/* <input value={this.state.tableExcelSearchBox} onChange={(value)=>{this.setState({tableExcelSearchBox:value.target.value})}} placeholder={"Seach by " + this.state.ctitles.join(',')} style={{width: '100%',border: 'none',background: '#f8f9fa',padding: '7px',borderRadius: '5px'}} /> */}
                  </div>
                  <div className="col-3 mx-0 px-0">
                    <button className="btn btn-success" style={{ width: '100%', background: '#fceee3', border: 'none', color: '#fea963' }} onClick={() => this.searchFilter()}>Search</button>
                  </div>
                </div>
              </div> : null}

            <div>
              <table
                className="table fadeInUp stickyCol"
                style={{
                  animationDelay: "1s", width: "100%",
                  maxWidth: "100%", textTransform: "none", overflowX: 'scroll'
                }}
              >
                <thead>
                  <tr style={{ width: "100%" }}>
                    {this.state.ctitles.map((a, i) => (
                      <th
                        className="sticky state-heading"
                        key={i}
                        style={{
                          backgroundColor: "#F1F1F1",
                          border: "0",
                          whiteSpace: "nowrap",
                        }}
                        onClick={(e) => this.handleSort(e)}
                      >
                        <div className="heading-content">
                          <abbr
                            title={a}
                            style={{ color: "#343a40", textAlign: "left" }}
                          >
                            {a}
                          </abbr>
                          <div
                            style={{
                              display:
                                this.state.sortData.sortColumn ===
                                  this.state.ckeys[i]
                                  ? "initial"
                                  : "none",
                            }}
                          >
                            {this.state.sortData.isAscending ? (
                              <i
                                className="fa fa-caret-up"
                                aria-hidden="true"
                                style={{
                                  fontSize: "0.8em",
                                  marginLeft: "7px",
                                  marginTop: "7px",
                                }}
                              ></i>
                            ) : (
                              <i
                                className="fa fa-caret-down"
                                aria-hidden="true"
                                style={{
                                  fontSize: "0.8em",
                                  marginLeft: "7px",
                                  marginTop: "7px",
                                }}
                              ></i>
                            )}
                          </div>
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody>
                  {this.state.data.map((a, i) => (
                    a['tableExcelSearchBoxshowFlag'] == null || a['tableExcelSearchBoxshowFlag'] == true ?
                      <tr key={i}>
                        {this.state.ckeys.map((b, j) => {
                          if (b.includes(".")) {
                            // const value = nst[i * (nst.length / this.state.data.length) + j];
                            let value = _.get(a, b)
                            return (
                              <td
                                style={
                                  !(i % 2)
                                    ? {
                                      fontWeight: "600",
                                      maxWidth: "300px",
                                      borderTop: "0",
                                      color: "#6c757d",
                                      background: "#f8f9fa",
                                    }
                                    : {
                                      fontWeight: "600",
                                      maxWidth: "300px",
                                      borderTop: "0",
                                      color: "#6c757d",
                                    }
                                }
                                key={j}
                              >
                                {
                                  this.isLink(value) ? (<a href={value} target="_blank"><i class="fa fa-link mr-1"></i></a>) : value
                                }
                              </td>
                            );
                          }
                          else if (b === "#") {
                            return (
                              <td
                                style={
                                  !(i % 2)
                                    ? {
                                      fontWeight: "600",
                                      maxWidth: "300px",
                                      borderTop: "0",
                                      color: "#6c757d",
                                      background: "#f8f9fa",
                                    }
                                    : {
                                      fontWeight: "600",
                                      maxWidth: "300px",
                                      borderTop: "0",
                                      color: "#6c757d",
                                    }
                                }
                                key={j}
                              >
                                {
                                  this.isLink(a[b]) ? (<a href={a[b]} target="_blank"><i class="fa fa-link mr-1"></i></a>) : a[b]
                                }
                              </td>
                            );
                          }
                          else {
                            return (
                              <td
                                style={
                                  !(i % 2)
                                    ? {
                                      fontWeight: "600",
                                      maxWidth: "300px",
                                      borderTop: "0",
                                      color: "#6c757d",
                                      background: "#f8f9fa",
                                    }
                                    : {
                                      fontWeight: "600",
                                      maxWidth: "300px",
                                      borderTop: "0",
                                      color: "#6c757d",
                                    }
                                }
                                key={j}
                                onClick={() =>
                                  this.onClick(
                                    a[b],
                                    i * this.state.ctitles.length + j
                                  )
                                }
                              >
                                {a[b]
                                  ? this.isLink(a[b]) ? (<a href={a[b]} target="_blank"><i class="fa fa-link mr-1"></i></a>) : (this.isinLinkKeys(b) ? (<a href={a[b]} target="_blank">{this.returnIndexLTitle(b)}</a>) : a[b].length > this.state.truncateTextView &&
                                    !isTruncate[i * this.state.ctitles.length + j]
                                    ? `${a[b].substring(
                                      0,
                                      this.state.truncateTextView
                                    )}...`
                                    : a[b])
                                  : "-"}
                              </td>
                            );
                          }
                        })}
                      </tr> : null
                  ))}
                </tbody>
              </table>
            </div>
            <div id="paginationWrapper" style={Styles.paginationWrapper}>
              {this.state.itempp && this.state.pagination && pages.length > 1 ? (
                <div style={Styles.paginationWrapper}>
                  {this.state.pagination.last_page === "1" ? "" : <>
                    <div style={{ display: "flex" }}>
                      <button id={this.state.pagination.current_page !== "1" ? "page" : "pageButtonDisabled"} style={Styles.pageBtn} onClick={() => this.changePage(prevPage - 1)}>
                        &laquo; Prev
                      </button>
                      <div style={{ display: "flex", justifyContent: "center", margin: "0 1rem", gap: "1rem" }}>
                        <button
                          id={this.state.pagination.current_page == 1 ? "pageSelected" : "page"}
                          style={Styles.pageBtn}
                          onClick={() => this.changePage(0)}
                        >
                          {1}
                        </button>
                        {this.state.pagination.current_page - 1 < 3 || this.state.pagination.current_page - 2 == 2 ? <button
                          id={this.state.pagination.current_page == 2 ? "pageSelected" : "page"}
                          style={Styles.pageBtn}
                          onClick={() => this.changePage(1)}
                        >
                          {2}
                        </button> : ""}
                        {this.state.pagination.current_page - 1 > 2 && this.state.pagination.current_page - 2 != 2 && <button
                          id="page"
                          style={Styles.pageBtn}
                        >
                          {"..."}
                        </button>}
                        {this.state.pagination.current_page != 1 && this.state.pagination.current_page != 2 && this.state.pagination.current_page != 3 && this.state.pagination.current_page != this.state.pagination.last_page && <button
                          id="page"
                          style={Styles.pageBtn}
                          onClick={() => this.changePage(parseInt(this.state.pagination.current_page) - 2)}
                        >
                          {parseInt(this.state.pagination.current_page) - 1}
                        </button>}
                        {this.state.pagination.current_page != 1 && this.state.pagination.current_page != 2 && this.state.pagination.current_page != this.state.pagination.last_page - 1 && this.state.pagination.current_page != this.state.pagination.last_page && <button
                          id={this.state.pagination.current_page == this.state.pagination.current_page ? "pageSelected" : "page"}
                          style={Styles.pageBtn}
                          onClick={() => this.changePage(parseInt(this.state.pagination.current_page) - 1)}
                        >
                          {this.state.pagination.current_page}
                        </button>}
                        {this.state.pagination.current_page != 1 && this.state.pagination.current_page != this.state.pagination.last_page && this.state.pagination.current_page != this.state.pagination.last_page - 1 && this.state.pagination.current_page != this.state.pagination.last_page - 2 && <button
                          id="page"
                          style={Styles.pageBtn}
                          onClick={() => this.changePage(parseInt(this.state.pagination.current_page))}
                        >
                          {parseInt(this.state.pagination.current_page) + 1}
                        </button>}
                        {this.state.pagination.last_page - this.state.pagination.current_page > 2 && this.state.pagination.last_page - 1 != parseInt(this.state.pagination.current_page) + 2 && this.state.pagination.last_page - 1 - this.state.pagination.current_page > 1 && <button
                          id="page"
                          style={Styles.pageBtn}
                        >
                          {"..."}
                        </button>}
                        {this.state.pagination.last_page - this.state.pagination.current_page < 3 || this.state.pagination.last_page - 1 == parseInt(this.state.pagination.current_page) + 2 ? <button
                          id={this.state.pagination.current_page == this.state.pagination.last_page - 1 ? "pageSelected" : "page"}
                          style={Styles.pageBtn}
                          onClick={() => this.changePage(this.state.pagination.last_page - 2)}
                        >
                          {this.state.pagination.last_page - 1}
                        </button> : ""}
                        <button
                          id={this.state.pagination.current_page == this.state.pagination.last_page ? "pageSelected" : "page"}
                          style={Styles.pageBtn}
                          onClick={() => this.changePage(this.state.pagination.last_page - 1)}
                        >
                          {this.state.pagination.last_page}
                        </button>
                      </div>
                      <button id={this.state.pagination.current_page != this.state.pagination.last_page ? "page" : "pageButtonDisabled"} style={Styles.pageBtn} onClick={() => this.changePage(prevPage + 1)}>
                        Next &raquo;
                      </button>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", margin: "0 10px" }}>
                      Showing results {this.state.pagination.from} to {this.state.pagination.to} of {this.state.pagination.total}
                    </div>
                  </>}
                </div>
              ) : (
                this.state.data.length > 0 ?
                  "" : <p style={{ width: "100%", textAlign: "center" }}>No Result Found</p>
              )}
            </div>
          </div>
        </div>
      );
  }
}


const Styles = {
  paginationWrapper: { margin: "25px 0", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "1rem" },
  pageBtn: {
    borderBottomLeftRadius: "5px",
    borderTopLeftRadius: "5px",
  }
}