import React, { Component } from "react";
import { BrowserRouter as Router,Switch, Route,HashRouter } from "react-router-dom";
import HomeTable from "./components/HomeTable";
import GenericReports from "./GenericReports";
import BulkUpload from './components/bulkUpload'
import Buttons from "./components/Buttons";
export default class App extends Component {
  render() {
    return (
      <div>
        <HashRouter>
          <Switch>
            <Route path="/bulkUpload">
              <BulkUpload />
            </Route>
            <Route path="/reports">
              <GenericReports />
            </Route>
            <Route path="/">
              <Buttons />
              <HomeTable />
            </Route>
          </Switch>
        </HashRouter>
      </div>
    );
  }
}
